import _ from 'lodash';

import * as constants from '../constants';
import { getAllMembersPagesRefs } from '../services/pages';
import { getController } from '../wrappers/controllers';
import { getPageData } from '../wrappers/pages.ts';
import { isInMembersAreaSubPage } from '../pages';
import { getAllCompsByApplicationId } from '../wrappers/tpa';
import {
  addSubPagesMenu,
  getSOSPContainerRef,
  getComponentLayout,
  updateComponentLayout,
  removeComponent,
  getSOSPProfileCardComponentRef,
  fixSOSPHeightForVerticalLayout,
  getById,
  getComponentChildren,
  updateFullStyle,
} from '../wrappers/components';

async function relayoutSOSP({ editorSDK, sospContainerRef, pwComponentRef, isHorizontal }) {
  const sospLayout = _.cloneDeep(
    isHorizontal ? constants.SOSP_CONTAINER_HORIZONTAL.layout : constants.SOSP_CONTAINER.layout,
  );
  const sospStyle = _.cloneDeep(
    isHorizontal ? constants.SOSP_CONTAINER_HORIZONTAL.style : constants.SOSP_CONTAINER.style,
  );
  const headerRef = await editorSDK.siteSegments.getHeader('');
  const headerLayout = await getComponentLayout({ editorSDK, componentRef: headerRef });
  sospLayout.y += headerLayout.height;

  await Promise.all([
    updateComponentLayout({ editorSDK, componentRef: sospContainerRef, layout: sospLayout }),
    updateFullStyle({ editorSDK, componentRef: sospContainerRef, style: sospStyle }),
  ]);

  // Workarounding layouting issues in document services.. need to trigger it multiple times to sit correctly
  setTimeout(
    () => updateComponentLayout({ editorSDK, componentRef: sospContainerRef, layout: { y: sospLayout.y } }),
    0,
  );
  setTimeout(
    () => updateComponentLayout({ editorSDK, componentRef: sospContainerRef, layout: { height: sospLayout.height } }),
    0,
  );

  // Layout vertical sidebar with proper margins
  if (!isHorizontal) {
    await fixSOSPHeightForVerticalLayout({ editorSDK, pwComponentRef, sospContainerRef });
  }
}

// Deleting other than PW components (including menus) in SOSP to avoid layout issues
async function clearSOSPContainer({ editorSDK, pwComponentRef, sospContainerRef }) {
  const sospChildrenRefs = await getComponentChildren({ editorSDK, componentRef: sospContainerRef });
  const unexpectedComponentsRefs = sospChildrenRefs.filter((comp) => !_.isEqual(comp, pwComponentRef));
  await Promise.all(unexpectedComponentsRefs.map((componentRef) => removeComponent({ editorSDK, componentRef })));
}

async function addMenuToSOSP({ editorSDK, isHorizontal, sospContainerRef }) {
  const controllerRef = await getController(editorSDK);
  await addSubPagesMenu(editorSDK, constants.MENU_IDS.SUB_MENU_ID, sospContainerRef, controllerRef, isHorizontal);
}

async function relayoutPW({ editorSDK, pwComponentRef, isHorizontal }) {
  const layout = isHorizontal ? constants.PW_HORIZONTAL_LAYOUT : constants.PW_VERTICAL_LAYOUT;
  await updateComponentLayout({ editorSDK, componentRef: pwComponentRef, layout });
}

async function getAllMAPagesTPASectionsComponents({ editorSDK }) {
  const allPagesRefs = await getAllMembersPagesRefs({ editorSDK });
  const allPagesDatas = await Promise.all(allPagesRefs.map((pageRef) => getPageData({ editorSDK, pageRef })));
  const allVerticalsPagesDatas = allPagesDatas.filter(
    (pageData) => pageData.tpaApplicationId && pageData.tpaApplicationId > 0,
  );
  const allCustomPagesDatas = allPagesDatas.filter(
    (pageData) => typeof pageData.tpaApplicationId === 'undefined' || pageData.tpaApplicationId === 0,
  );

  const allCustomPagesIds = allCustomPagesDatas.map((pageData) => pageData.id);
  const allApplicationsIds = allVerticalsPagesDatas.map((pageData) => pageData.tpaApplicationId);
  const [allCustomPagesCompRefs, allApplicationsComponents] = await Promise.all([
    Promise.all(allCustomPagesIds.map((id) => getById({ editorSDK, id }))),
    Promise.all(allApplicationsIds.map((applicationId) => getAllCompsByApplicationId({ editorSDK, applicationId }))),
  ]);

  const allApplicationsComponentsFlat = allApplicationsComponents.reduce((acc, comps) => [...acc, ...comps], []);
  const onlyMAPagesComponents = allApplicationsComponentsFlat.filter(
    (comp) => allPagesDatas.map((data) => data.id).indexOf(comp.pageId) > -1,
  );

  const [allCustomPagesComponents, allVerticalsPagesComponentsRefs] = await Promise.all([
    Promise.all(allCustomPagesCompRefs.map((componentRef) => getComponentChildren({ editorSDK, componentRef }))),
    Promise.all(onlyMAPagesComponents.map((comp) => getById({ editorSDK, id: comp.id }))),
  ]);

  const allCustomPagesComponentsFlat = allCustomPagesComponents.reduce((acc, comps) => [...acc, ...comps], []);

  return { allVerticalsPagesComponentsRefs, allCustomPagesComponentsFlat };
}

async function maybeUpdateCustomPageComponentLayout({ editorSDK, componentRef, isHorizontal }) {
  const componentLayout = await getComponentLayout({ editorSDK, componentRef });

  // If components are out of main section, don't do anything with them
  if (componentLayout.x < 0 || componentLayout.x > constants.CLASSIC_EDITOR_MAIN_SECTION_WIDTH) {
    return;
  }

  // Move components left and down if layout is being changed to horizontal
  const diffX = constants.SECTION_DEFAULT_LAYOUT.x - constants.SECTION_DEFAULT_LAYOUT_HORIZONTAL.x;
  const diffY = constants.SECTION_DEFAULT_LAYOUT.y - constants.SECTION_DEFAULT_LAYOUT_HORIZONTAL.y;

  const newLayout = { ...componentLayout };
  if (isHorizontal) {
    newLayout.x = componentLayout.x - diffX;
    newLayout.y = componentLayout.y - diffY;
  } else {
    newLayout.x = componentLayout.x + diffX;
    newLayout.y = componentLayout.y + diffY;
  }

  await updateComponentLayout({ editorSDK, componentRef, layout: newLayout });
}

async function relayoutMASections({ editorSDK, isHorizontal }) {
  const { allVerticalsPagesComponentsRefs, allCustomPagesComponentsFlat } = await getAllMAPagesTPASectionsComponents({
    editorSDK,
  });
  const verticalsComponentsLayout = isHorizontal
    ? constants.SECTION_DEFAULT_LAYOUT_HORIZONTAL
    : constants.SECTION_DEFAULT_LAYOUT;

  const allPromises = [
    ...allVerticalsPagesComponentsRefs.map((componentRef) =>
      updateComponentLayout({ editorSDK, componentRef, layout: verticalsComponentsLayout }),
    ),
    ...allCustomPagesComponentsFlat.map((componentRef) =>
      maybeUpdateCustomPageComponentLayout({ editorSDK, componentRef, isHorizontal }),
    ),
  ];

  return Promise.all(allPromises);
}

async function relayoutMA({ editorSDK, isHorizontal }) {
  const isInMembersArea = await isInMembersAreaSubPage(editorSDK);

  if (!isInMembersArea) {
    return;
  }

  const sospContainerRef = await getSOSPContainerRef(editorSDK);
  const pwComponentRef = await getSOSPProfileCardComponentRef({ editorSDK });

  if (!pwComponentRef) {
    return;
  }

  // Not in parallel because editor struggles to handle this relayouting
  // Must layout SOSP last as otherwise it can be weirdly stretched by the components inside
  await clearSOSPContainer({ editorSDK, sospContainerRef, pwComponentRef });
  await relayoutPW({ editorSDK, pwComponentRef, isHorizontal });
  await addMenuToSOSP({ editorSDK, sospContainerRef, isHorizontal });
  await relayoutSOSP({ editorSDK, sospContainerRef, pwComponentRef, isHorizontal });
  await relayoutMASections({ editorSDK, isHorizontal });
}

function setHorizontalLayout(editorSDK) {
  return relayoutMA({ editorSDK, isHorizontal: true });
}

function setSidebarLayout(editorSDK) {
  return relayoutMA({ editorSDK, isHorizontal: false });
}

// This doesn't support app widgets yet, need to make sure this also works with app widgets when enabling them
export { setHorizontalLayout, setSidebarLayout };
