import { EditorSDK } from '@wix/platform-editor-sdk';
import { IntegrationApplication } from '@wix/members-area-integration-kit';
import { getDataByAppDefId } from '../wrappers/tpa';
import { log } from '../../utils/monitoring';
import { SANTA_MEMBERS_APP_ID } from '../constants';
import * as pagesService from '../services/pages';
import * as pagesWrapper from '../wrappers/pages';

// A method to remove pages, which are left in the site but not in MA routers, preventing them to be added again
// We assume MA pages should always be in MA, therefore we delete these loose pages (which are there because of some error)
export const removeLoosePagesOutsideMembersArea = async (
    editorSDK: EditorSDK,
    registeredApplications: IntegrationApplication[],
) => {
    try {
        const applicationsDatas = await Promise.all(registeredApplications.map(app => 
            getDataByAppDefId({ editorSDK, appDefinitionId: app.appDefinitionId })
                .then(appData => ({
                    applicationId: appData ? appData.applicationId : null,
                    appDefinitionId: app.appDefinitionId,
                    pageId: app.pageId,
                }))
        ))

        const installedApplications = applicationsDatas.filter(app => !!app.applicationId)
        const allPages = await pagesWrapper.getAllPages({ editorSDK });
        const allMAPagesRefs = await pagesService.getAllMembersPagesRefs({ editorSDK });
        const integrationalPages = allPages.filter(page => !!installedApplications.find(app => page.tpaPageId === app.pageId && page.tpaApplicationId === app.applicationId))
        const loosePages = integrationalPages.filter(page => !allMAPagesRefs.find(ref => ref.id === page.id))
        const editableLoosePages = loosePages.filter(page => !page.managingAppDefId || page.managingAppDefId === SANTA_MEMBERS_APP_ID)

        if (editableLoosePages.length !== loosePages.length) {
            const alreadyManagedPages = loosePages.filter(page => !!page.managingAppDefId && page.managingAppDefId !== SANTA_MEMBERS_APP_ID)
            log('Could not delete loose pages because it is already managed by some application', {extra: {alreadyManagedPages}})
        }
        
        await Promise.all(editableLoosePages.filter(p => !p.managingAppDefId).map(page => pagesService.updatePageWithManagingAppDefId({ editorSDK, pageRef: {id: page.id} })))
        await Promise.all(editableLoosePages.map(page => editorSDK.pages.remove('', {pageRef: {id: page.id || ''}, shouldShowEditorRemovePanel: false})))
        
        if (editableLoosePages.length > 0) {
            log('Succesfully removed loose MA pages outside MA routers', {extra: {registeredApplications, loosePages}})
        }
    } catch(error) {
        log('An error has occured when searching for loose MA pages outside MA routers', {extra: {error, registeredApplications}})
    }
}
